<template>
  <div class="sqlTemplate">
    <codemirror
      ref="mySql"
      v-model="sql"
      :options="sqlOptions"
    ></codemirror>
  </div>
</template>

<script>
// Vue.config.productionTip = false;
</script>
<script>
import _ from 'lodash';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/sql/sql.js'; // 引入mode
import 'codemirror/theme/solarized.css'; // 引入theme
import { codemirror } from 'vue-codemirror';
import 'codemirror/theme/idea.css';
// import 'codemirror/mode/shell/shell'
// 代码提示功能 具体语言可以从 codemirror/addon/hint/ 下引入多个
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint';
// 高亮行功能
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/selection/selection-pointer';
// 全屏功能 由于项目复杂，自带的全屏功能一般不好使
import 'codemirror/addon/display/fullscreen.css';
import 'codemirror/addon/display/fullscreen';
import 'codemirror/addon/display/autorefresh'; //及时自动更新，配置里面也需要设置autoRefresh为true
export default {
  components: { codemirror },
  model: {
    // 定义model
    prop: 'expressionValue', // 父组件v-model绑定的值传递给props中的fatherValue
    event: 'expressionValue',
    // 通过emit触发childValueChange将内部值传递给父组件v-model绑定的值
  },
  props: {
    // 回显code
    expressionValue: {
      type: String,
      default: '',
    },
    // 自定义提示列表
    hintList: {
      type: Array,
      default: () => [],
    },
    // 自定义提示列表中对应的字段标识
    hintLabel: {
      type: String,
      default: '',
    },
    // 字段分隔符，根据字段分隔符做出截取需要提示的字段
    splitRules: {
      type: Array,
      default: () => [],
    },
    // 是否开启输入提示
    isHint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sql: this.expressionValue,
      list: [],
      sqlOptions: {
        tabSize: 4,
        lineNumbers: false,
        line: true,
        mode: 'text/x-mssql', // SQL SERVER
        smartIndent: true,
        indentUnit: 4,
        autoRefresh: true,
        theme: 'default',
        lineWrapping: true,
        // 高亮行功能
        styleActiveLine: true,
        autofocus: true,
        hintOptions: {
          completeSingle: false,
          hint: this.handleShowHint,
        },
      },
      earlayRightIndex: 0,
    };
  },
  watch: {
    expressionValue: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.sql = newVal;
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.sql = this.expressionValue;
    //当用户有输入时，显示提示信息
    this.$refs.mySql.codemirror.on('inputRead', (mySql) => {
      if (!this.isHint) {
        return;
      }
      mySql.showHint();
    });
    //当用户按删除键时，显示提示信息
    this.$refs.mySql.codemirror.on('keyHandled', (mySql, name) => {
      if (!this.isHint) {
        return;
      }
      if (name == 'Backspace') {
        mySql.showHint();
      }
    });
    //当有内容更新时，向父组件更新
    this.$refs.mySql.codemirror.on('update', (mySql) => {
      this.$emit('expressionValue', this.sql);
    });
    // 设置输入区域高度
    this.$refs.mySql.codemirror.setSize('auto', 100 + 'px');
    // this.$nextTick(() => {
    //   window.addEventListener('resize', () => {
    //     // 监听浏览器窗口大小改变
    //     // 浏览器变化执行动作
    //     this.$refs.mySql.codemirror.setSize('auto', 100 + 'px')
    //   })
    // })
  },
  methods: {
    handleShowHint() {
      // 获取输入框实例
      const cmInstance = this.$refs.mySql.codemirror;
      // 得到光标
      let cursor = cmInstance.getCursor();
      // 得到行内容
      let cursorLine = cmInstance.getLine(cursor.line);
      // 得到光标位置
      let end = cursor.ch;
      this.list = [];
      // 得到光标标识
      let token = cmInstance.getTokenAt(cursor);
      // 获取提示内容
      this.getListHint(cursorLine, end);
      // console.log(cursor, cursorLine, token, this.earlayRightIndex, this.list);
      return {
        list: this.list, // 自定义提示内容
        // ch:选择提示内容替换的开始位置，line: 光标所在行
        from: { ch: this.earlayRightIndex, line: cursor.line },
        to: { ch: token.end, line: cursor.line },
      };
    },
    // 获取提示内容
    getListHint(cursorLine, cursorIndex) {
      let indexList = [];
      for (let i = 0; i < cursorIndex; i++) {
        // 获取所有分隔符小于当前光标的位置
        if (this.splitRules.includes(cursorLine[i])) indexList.push(i);
      }
      //   console.log(indexList, 'indexList');
      // 得到当前距离光标最近且小于光标位置的字符位置
      const earlayRightIndex = indexList[indexList.length - 1];
      //   console.log(earlayRightIndex, 'earlayRightIndex');
      this.earlayRightIndex = earlayRightIndex ? earlayRightIndex : 0;
      // 截取光标与最近且位置坐标小于光标的内容
      const str = cursorLine.substring(earlayRightIndex + 1, cursorIndex);
      //   console.log(str, 'str');
      // 遍历自定义提示数组，得到满足条件的提示内容
      this.hintList.forEach((item) => {
        if (item[this.hintLabel].indexOf(str) !== -1 && str) {
          this.list.push(item[this.hintLabel]);
        }
      });
    },
    
  },
};
</script>

<style></style>
